import React from "react";
import {
  HeroImage,
  MainButtons,
  MainDescription,
  MainDetails,
  MainHero,
  MainImage,
  MainTitleLine,
  MainTitleTextGradient,
  MainTitle,
  SocialButton,
} from "../styles/Hero.styled";

import {
  BsArrowUpRightCircleFill as ArrowIcon,
  BsFillPlayCircleFill as PlayIcon,
} from "react-icons/bs";
import heroImg from "../../assets/tmi_profile.jpeg";
import tokLogo from "../../assets/tiktok_logo_bw.png";
import gramLogo from "../../assets/ig_bw_logo.png";
import emailLogo from "../../assets/email_logo_bw.png"

const Hero = () => {
  return (
    <MainHero>
      <MainDetails>
        <MainTitle>
          <MainTitleTextGradient> <MainTitleLine>Thomas Madrigal Inigo.</MainTitleLine> <br />
          <MainTitleLine> Triathlete. Marathoner. Coach. </MainTitleLine></MainTitleTextGradient>
        </MainTitle>
        <MainDescription>
          I am an aspiring professional triathlete, coach, and trainer. Check out my socials below to see my journey and get into contact with me for
          coaching inquiries, questions, or any other connections!
        </MainDescription>
        <MainButtons>
          <SocialButton as={"a"} target="_blank" href={"https://www.instagram.com/tmi_does_tri/"}>
            <img src={gramLogo} alt="IG" height={"35px"} width={"35px"}/>
          </SocialButton>
          <SocialButton as={"a"} target={"_blank"} href={"https://www.tiktok.com/@tmi_does_tri"}>
            <img src={tokLogo} alt="TikTok" height={"35px"} width={"35px"}/>
          </SocialButton>
          <SocialButton as={"a"} target={"_blank"} href={"mailto:tinigo7@gmail.com"}>
            <img src={emailLogo} alt="Email" height={"30px"} width={"35px"}/>
          </SocialButton>
        </MainButtons>
      </MainDetails>

      <MainImage>
        <HeroImage src={heroImg} alt="main image"/>
      </MainImage>
    </MainHero>
  );
};

export default Hero;
