export const membershipCarDatas = [
  {
    id: 2,
    race: "2022 Luray Olympic Distance",
    price: "1st  ",
    anuallyPrice: "Age Group",
    membershipIncludes: [
      {
        id: 1,
        membershipIncludes: "2nd Overall",
        included: false,
      },
      {
        id: 2,
        membershipIncludes: "2nd fastest run split on the course",
        included: true,
      },
    ],
    primier: false,
  },
  {
    id: 1,
    race: "2022 Ironman Wilmington 70.3",
    price: "1st  ",
    anuallyPrice: "Age Group",
    membershipIncludes: [
      {
        id: 1,
        membershipIncludes: "7th Overall",
        included: false,
      },
      {
        id: 2,
        membershipIncludes: "Swim: 26:30",
        included: true,
      },
      {
        id: 3,
        membershipIncludes: "Bike: 2:26",
        included: true,
      },
      {
        id: 4,
        membershipIncludes: "Run: 1:23",
        included: true,
      },
      {
        id: 5,
        membershipIncludes: "2nd fastest run split on the course",
        included: true,
      },

      {
        id: 6,
        membershipIncludes: "2023 70.3 World Championship Qualification",
        included: false,
      },
      {
        id: 7,
        membershipIncludes: "2023 USAT National Championship Qualification",
        included: false,
      },
    ],
    primier: true,
  },
  {
    id: 3,
    race: "Greenbelt Marathon",
    price: "1st ",
    anuallyPrice: "Overall",
    membershipIncludes: [
      {
        id: 1,
        membershipIncludes: "2020: 3:02, 1st Overall",
        included: true,
      },
      {
        id: 2,
        membershipIncludes: "2021: 2:57, 2nd Age Group, 3rd Overall",
        included: true,
      },
    ],
    primier: false,
  },
];
