import React from "react";
import { StyledButton } from "../styles/Button.styled";
import {
  CloseMenu,
  MobileBottomText,
  MobileHeader,
  MobileImg,
  MobileLink,
  MobileList,
  MobileLogo,
  MobileMenuImage,
  MobileNavList,
  MobileStyledNav,
} from "../styles/MobileNav.styled";
import Image from "../../assets/program1-img.png";

const MobileNav = ({ isOpen, toggleHandler }) => {
  return (
    <MobileStyledNav isOpen={isOpen}>
      <MobileHeader>
        <MobileLogo>TMI</MobileLogo>
        <CloseMenu onClick={toggleHandler} />
      </MobileHeader>
      <MobileNavList>
        <MobileList>
          <MobileLink
            to="/"
            smooth={true}
            duration={650}
            delay={0}
            spyThrottle={0}
            onClick={toggleHandler}
          >
            Home
          </MobileLink>
        </MobileList>
        <MobileList>
          <MobileLink
            to="programs"
            smooth={true}
            duration={650}
            delay={0}
            spyThrottle={0}
            onClick={toggleHandler}
          >
            Me
          </MobileLink>
        </MobileList>
        <MobileList>
          <MobileLink
            to="memberships"
            smooth={true}
            duration={650}
            delay={0}
            spyThrottle={0}
            onClick={toggleHandler}
          >
            Races
          </MobileLink>
        </MobileList>
      </MobileNavList>
      <MobileBottomText>Thomas Inigo</MobileBottomText>
    </MobileStyledNav>
  );
};

export default MobileNav;
