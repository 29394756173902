import tBikeImg from "./assets/thomas_on_bike.jpg";
import bikeNatureImg from "./assets/bike_photo_nature.jpg";

export const programData = [
  {
    id: 1,
    img: tBikeImg,
    title: "Personal Info",
    details:
      "Thomas Madrigal Inigo",
    details2:
      "DOB: December 14, 1998",
    details3:
      "Height: 6'1",
    details4:
      "Weight: 150 lbs",
    imgStart: true,
    line: true,
  },
  {
    id: 2,
    img: bikeNatureImg,
    title: "Measurables",
    details:
        "5k pace: 5:25 min/mi",
    details2:
        "10k pace: 5:50 min/mi",
    details3:
        "Lactate Threshold 1 - 165 BPM",
    details4:
        "Lactate Threshold 2 - 176 BPM",
    details5:
        "FTP: 270W (3.9 W/kg)",
    details6:
        "Threshold swim pace: 1:17/100yd",
    imgStart: true,
    line: true,
  },
];
