import React from "react";
import { StyledButton } from "../styles/Button.styled";
import { BsFillPlayCircleFill as PlayIcon } from "react-icons/bs";
import {
  CardDetails,
  CardImage,
  CardImg,
  SectionCardDetails,
  SectionCardTitle,
  StyledCard,
} from "../styles/Card.styled";
import {PopularPricing} from "../styles/MembershipCard.styled";

const ProgramCard = ({ img, title, details, details2, details3, details4, details5, details6, imgStart, line }) => {
  return (
    <StyledCard imgStart={imgStart}>
      <CardImage imgStart={imgStart} line={line}>
        <CardImg src={img} alt={title} />
      </CardImage>
      <CardDetails>
        <SectionCardTitle>{title}</SectionCardTitle>
        {details && <SectionCardDetails>{details}</SectionCardDetails>}
        {details2 && <SectionCardDetails>{details2}</SectionCardDetails>}
        {details3 && <SectionCardDetails>{details3}</SectionCardDetails>}
        {details4 && <SectionCardDetails>{details4}</SectionCardDetails>}
        {details5 && <SectionCardDetails>{details5}</SectionCardDetails>}
        {details6 && <SectionCardDetails>{details6}</SectionCardDetails>}
      </CardDetails>
    </StyledCard>
  );
};

export default ProgramCard;
