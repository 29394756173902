import React from "react";
import {
  SectionDescription,
  SectionTitle,
} from "../styles/SectionHeaders.styled";
import { programData } from "../../programData";
import ProgramCard from "./ProgramCard";
import { Section, SectionCards } from "../styles/Sections.styled";

const Program = () => {
  return (
    <Section id="programs">
      <SectionTitle>Who I am as an athlete.</SectionTitle>
      <SectionDescription>

      </SectionDescription>
      <SectionCards>
        {programData.map((program) => (
          <ProgramCard key={program.id} {...program} />
        ))}
      </SectionCards>
    </Section>
  );
};

export default Program;
